import { useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';

export function useForm(initialFValues) {
  const [values, setValues] = useState(initialFValues);

  const handleInputChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    setValues,
    handleInputChange,
  };
}

export function Form(props) {
  
  const { children, ...other } = props;
  return (
    <AvForm {...other}>
      {props.children}
    </AvForm>
  );
}
