import { Route, Redirect } from 'react-router';
import { toast } from 'react-toastify';

/**
 *
 * @param {*} msg
 */
 export const showErrorSnackbar = (msg) => toast.error(msg);
 /**
  *
  * @param {*} msg
  */
 export const showSuccessSnackbar = (msg) => toast.success(msg);

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const authentication = {
  isLoggedIn: false,
  onAuthentication() {
    this.isLoggedIn = true;
  },
  getLogInStatus() {
    return this.isLoggedIn;
  },
};

export const SecuredRoute = (props) => {
  const token = localStorage.getItem('authToken');
  return (
    <Route
      path={props.path}
      render={(data) =>
        token && token ? (
          <props.component {...data}></props.component>
        ) : (
          <Redirect to={{ pathname: '/' }}></Redirect>
        )
      }
    ></Route>
  );
};

