import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Alert,
} from 'reactstrap';
import Input from '../hooks/inputHooks';
import { useForm, Form } from '../hooks/useFormHooks';
import { emailRegex } from '../helper';

const initialFValues = {
  email: '',
  password: '',
};
export const Login = (props) => {
  const { values, handleInputChange } = useForm(initialFValues);
  const history = useHistory();
  const [error, setError] = useState(false);

  // form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(values);
    if (emailRegex.test(values.email) && values.password) {
      if (
        values.email === process.env.REACT_APP_EMAIL &&
        values.password === process.env.REACT_APP_PASSWORD
      ) {
        localStorage.setItem('authToken', process.env.REACT_APP_TOKEN);
        setTimeout(()=> {
          history.push('/push-notification');
        },500);
        
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    }
  };
  // preventing back after login
  useEffect(() => {
    let isAuth = localStorage.getItem('authToken');
    if (!isAuth) {
      history.push('/');
    } else {
      window.location.reload();
      history.push('/push-notification');
    }
  }, []);

  return (
    <>
      <div className="loginBody">
        <Container>
          <Row>
            <Col lg={4} md={5} sm={4} xs={12} className="mx-auto">
              <div className="cardFormHeight">
                <Card className="w-100 loginFormCard">
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <div className="text-center">
                        <h4>Connectez-vous à votre compte</h4>
                      </div>
                      {error && (
                        <Alert color="danger">
                          Se connecter échoué, S'il vous plaît essayer à nouveau.
                        </Alert>
                      )}
                      <FormGroup>
                        <Input
                          label="Email"
                          type="email"
                          name="email"
                          id="exampleEmail"
                          placeholder="Renseignez votre email"
                          value={values.email}
                          onChange={handleInputChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Email required',
                            },
                            pattern: {
                              value: emailRegex,
                              errorMessage: 'Invalid email',
                            },
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          label="Mot de passe"
                          type="password"
                          name="password"
                          id="examplePassword"
                          placeholder="Renseignez votre mot de passe"
                          value={values.password}
                          onChange={handleInputChange}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Mot de passe required',
                            },
                            minLength: {
                              value: 6,
                              errorMessage:
                                'La longueur du mot de passe doit être de 6 ou plus',
                            },
                            maxLength: { value: 16 },
                          }}
                        />
                      </FormGroup>
                      <div className="text-center">
                        <button
                          primary
                          className="btn btn-fe px-4 mt-3"
                          type="submit"
                        >
                          Se connecter
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
