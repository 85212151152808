import React, {useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
} from 'reactstrap';
import { Form } from '../hooks/useFormHooks';
import Input from '../hooks/inputHooks';
import axios from 'axios';
import {showSuccessSnackbar, showErrorSnackbar} from "../helper"

export const PushNotification = (props) => {
  // const { values, handleInputChange } = useForm(initialValues);
  const [values, setValues] = useState({
    title: "",
    body: "",
    application: ""
  })

  const token = "a3^zQ'NWH$6>j^cKwJy2~radiohit/HR";
  
  const handleSubmit = (event) => {
    // const initialValues = {
    //   title: '',
    //   body: '',
    //   application: "0"
    // };
    event.preventDefault();
    if (values.title !== "" && values.body !== "" && values.application !== "0" && values.application !== ""){
      axios(`${process.env.REACT_APP_API_URL}=${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(
        {
          "title": values.title,
          "body": values.body,
          "application": values.application
        }
      ),
    })
      .then(function (response) {
        showSuccessSnackbar("Push notification sent successfully");
        setTimeout(() => { window.location.reload()}, 1000);
      })
      .catch(function (error) {
        showErrorSnackbar("Something went wrong");
      });
    }
  };

  return (
    <>
      <div className="notificationBody">
        <Container>
          <Row>
            <Col lg={4} md={5} sm={4} xs={12} className="mx-auto">
              <div className="cardFormHeight">
                <Card className="w-100 loginFormCard">
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label for="exampleSelect">Station Radio</Label>
                        <Input type="select" id="exampleSelect" name="application" onChange={e => setValues({...values, application: e.target.value})}>
                          <option value="0">Choisissez une station radio</option>
                          <option value="HITRADIO">Hit Radio</option>
                          <option value="AZAWAN">Azawan</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          label="Titre"
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Renseignez le titre"
                          value={values.title}
                          onChange={e => setValues({...values, title: e.target.value})}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Titre required',
                            },
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          label="Message"
                          type="textarea"
                          name="body"
                          id="text"
                          placeholder="Renseignez le message"
                          value={values.body}
                          onChange={e => setValues({...values, body: e.target.value})}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Message required',
                            },
                          }}
                        />
                      </FormGroup>
                      <div className="text-center">
                        <button
                          primary
                          className="btn btn-fe px-4 mt-3 mx-2"
                          type="submit"
                        >
                          Envoyer
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
