export const ErrorPage = (props) => {
    return (
      <>
        <h1>Something went wrong</h1>
        <button
          className="btn btn-primary"
          onClick={() => props.history.push('/login')}
        >
         Login page
        </button>
      </>
    );
  };
  