import { Route, Switch } from 'react-router-dom';
import { Login } from './pages/login';
import { PushNotification } from './pages/pushNotification';
import { ErrorPage } from './pages/errorPage';
import { SecuredRoute } from './helper';
import { NavigationBar } from './Components/navbar';


function App() {

  return (
    <>
      <NavigationBar />
      <Switch>
        <Route exact path="/" component={Login} />
        <SecuredRoute
          exact
          path="/push-notification"
          component={PushNotification}
        />
        <Route component={ErrorPage} />
      </Switch>
    </>
  );
}

export default App;
