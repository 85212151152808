import { AvField } from 'availity-reactstrap-validation';

export default function Input(props) {
  const {
    name,
    type,
    id,
    placeholder,
    label,
    errorMessage,
    value,
    onChange,
    ...other
  } = props;
  return (
    <>
      <AvField
        label={label}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        errorMessage={errorMessage}
        {...other}
      />
    </>
  );
}
