import React from 'react';
import { withRouter } from 'react-router';
import { Navbar, NavbarBrand, NavItem } from 'reactstrap';
import Logo from '../assets/images/logo.png';

export const NavigationBar = withRouter((props) => {
  // logout
  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('firebaseToken');
    props.history.push('/');
    window.location.reload();
  };
  let isAuth = localStorage.getItem('authToken');
  return (
    <Navbar expand='md' className='nav-fixed'>
      <NavbarBrand href='/'>
        <img src={Logo} alt='hit radio' className='logoImg' />
      </NavbarBrand>
      {isAuth && <NavItem className="ml-auto mb-5" style={{"listStyle": "none"}}>
        <button
          className='btn btn-fe px-4 mt-3 mx-2'
          onClick={() => logout()}
        >
          Se déconnecter
        </button>
      </NavItem>}
    </Navbar>
  );
});
